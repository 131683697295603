<template>
  <div class="columns is-multiline">
    <div class="column is-8 is-offset-2 p-0 mb-3">
      <SdSelect rules="required" label="Term" v-model="termId">
        <option v-for="term in terms" :key="term.id" :value="term.id">
          {{ term.title }}
        </option>
      </SdSelect>
    </div>

    <div class="column is-8 is-offset-2 p-0 mb-3">
      <SdSelect rules="required" label="School Class" v-model="schoolClassId">
        <option
          v-for="schoolClass in schoolClasses"
          :key="schoolClass.id"
          :value="schoolClass.id"
        >
          {{ schoolClass.name }}
        </option>
      </SdSelect>
    </div>

    <template v-if="$apollo.loading">
      <div class="column is-8 is-offset-2">
        <loading />
      </div>
    </template>
    <template v-else>
      <div
        class="column is-8 is-offset-2 card mb-2"
        v-for="item in attendances"
        :key="item.student_id"
      >
        <p class="subtitle is-6 mb-0">{{ item.name }}</p>
        <div class="columns is-mobile is-multiline mt-1">
          <div
            class="column"
            v-for="(record, month) in item.attendance.records"
            :key="month"
          >
            <SdInput
              type="text"
              :label="getMonth(month)"
              v-model="record.total"
            />
          </div>
        </div>
      </div>

      <div class="column is-8 is-offset-2 p-0 mt-3">
        <div class="buttons is-right">
          <button class="button is-success" @click="takeFastAttendance()">
            Submit
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchUser } from '@/assets/js/app_info.js'
import TERMS from '../../../graphql/term/Terms.gql'
import SCHOOL_CLASSES from '../../../graphql/school_class/SchoolClasses.gql'
import ASSIGNED_SCHOOL_CLASSES from '../../../graphql/school_class/AssignedSchoolClasses.gql'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import { crudNotification } from '../../assets/js/notification'

export default {
  data() {
    return {
      schoolId: null,
      pageTitle: 'Fast Attendance',
      terms: [],
      termId: '',
      attendances: [],
      schoolClassAttendance: [],
      schoolClassId: null,
      schoolClassName: null,
      schoolClasses: [],
      monthsOfTheYear: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
      },
    }
  },
  apollo: {
    terms: {
      query: TERMS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
  },
  watch: {
    terms(data) {
      this.termId = data[0].id
    },
    termId() {
      this.attendances = []
      this.schoolClassId = null
    },
    schoolClassAttendance(data) {
      this.attendances = []
      data.studentsInSchool.forEach((student) => {
        this.attendances.push({
          student_id: student.id,
          name: student.name,
          attendance: student.termAttendance,
        })
      })
    },
    schoolClassId(id) {
      if (!id) return
      this.$apollo.addSmartQuery('schoolClassAttendance', {
        query: gql`
          query SchoolClassStudentsFastAttendanceQuery($id: ID!, $termId: ID!) {
            schoolClassAttendance(id: $id, termId: $termId) {
              studentsInSchool {
                id
                name
                termAttendance {
                  id
                  records
                  term {
                    id
                    months
                  }
                }
              }
            }
          }
        `,
        variables: {
          id: parseInt(id),
          termId: parseInt(this.termId),
        },
      })

      // this.$apollo.queries.schoolClassAttendance.refetch();
    },
  },
  components: {
    SdSelect,
    SdInput,
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)

    this.setParams()
  },
  methods: {
    fetchSchoolClasses() {
      if (this.user.role === 'host' || this.user.role === 'admin') {
        this.$apollo
          .query({
            query: SCHOOL_CLASSES,
            variables: {
              schoolId: this.schoolId,
            },
          })
          .then((response) => {
            this.schoolClasses = response.data.schoolClasses
            this.schoolClass = this.schoolClasses.filter(
              (schoolClass) => schoolClass.id == this.schoolClassId
            )[0].name
          })
          .catch((error) => {
            // console.error(error);
          })
      } else if (this.user.role === 'educator') {
        this.$apollo
          .query({
            query: ASSIGNED_SCHOOL_CLASSES,
            variables: {
              id: this.user.id,
            },
          })
          .then((response) => {
            this.schoolClasses =
              response.data.assignedSchoolClasses.schoolClasses
            this.schoolClass = this.schoolClasses.filter(
              (schoolClass) => schoolClass.id == this.schoolClassId
            )[0].name
          })
          .catch((error) => {
            // console.error(error);
          })
      }
    },
    setParams() {
      fetchUser().then((user) => {
        this.user = user
        this.schoolId = user.school_id
        this.fetchSchoolClasses()
        this.$store.commit('setSubMenus', [
          {
            name: 'Attendances',
            route: `/school/${this.schoolId}/attendances`,
          },
        ])
      })
    },
    getMonth(index) {
      return this.monthsOfTheYear[index]
    },
    takeFastAttendance() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation FastAttendanceQuery($termId: ID!, $records: JSON!) {
              fastAttendance(input: { termId: $termId, records: $records }) {
                errors
              }
            }
          `,
          variables: {
            termId: parseInt(this.termId),
            records: this.attendances,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.fastAttendance.errors,
            'Successfully updated.'
          )
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>
